var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-10" },
    [
      _c("div", { staticClass: "text-center" }, [
        _c(
          "div",
          [
            _vm.instance.meta.img.type === "mew-icon"
              ? _c("mew-icon", {
                  staticClass: "border-radius--5px custom-icon-style",
                  attrs: {
                    "icon-name": _vm.instance.meta.img.value,
                    "img-height": 100,
                  },
                })
              : _c("img", {
                  attrs: { src: _vm.instance.meta.img.value, height: "50px" },
                }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          class: [
            _vm.error !== "" ? "redPrimary--text" : "",
            "text-center mb-5",
          ],
        },
        [_c("p", [_vm._v(_vm._s(_vm.bodyText))])]
      ),
      _vm.error === "" && !_vm.signed
        ? _c("v-progress-linear", {
            staticClass: "mb-3",
            attrs: { indeterminate: "", color: "greenPrimary" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }