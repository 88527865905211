var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mx-auto mb-3", staticStyle: { "max-width": "550px" } },
      [
        _c("border-block", { staticClass: "mt-4 pa-3 pa-sm-5" }, [
          _c(
            "div",
            { staticClass: "overlayBg rounded pa-5" },
            [
              _c("mew-warning-sheet", {
                staticClass: "mb-5",
                attrs: {
                  title: "",
                  description:
                    "The withdrawal address can only be set once and can never be changed. Please make sure the withdrawal address you are setting is a non-custodial wallet to which you have full access with a recovery phrase or private key. Do NOT use an exchange address or a smart contract wallet.",
                },
              }),
              _c("div", { staticClass: "mew-heading-3 mb-3" }, [
                _vm._v("Your withdrawal address"),
              ]),
              _c("module-address-book", {
                ref: "addressInput",
                staticClass: "AddressInput",
                attrs: {
                  "preselect-curr-wallet-adr": true,
                  currency: _vm.currencyName,
                  label: "Address",
                },
                on: { setAddress: _vm.setAddress },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "mt-10 d-flex flex-column-reverse flex-md-row align-center justify-center",
          },
          [
            _c("mew-button", {
              staticClass: "d-block ma-2",
              attrs: {
                "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                "btn-size": "xlarge",
                title: "Back",
                "btn-style": "outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onBack.apply(null, arguments)
                },
              },
            }),
            _c("mew-button", {
              staticClass: "d-block ma-2",
              attrs: {
                "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                "btn-size": "xlarge",
                title: _vm.buttonText,
                disabled: !_vm.isValidAddress,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onContinue(false)
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }