var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "white-sheet",
    { staticClass: "the-wrapper-dapp" },
    [
      !_vm.isNewHeader
        ? _c("the-dapp-block-header", {
            attrs: {
              "text-obj": _vm.bannerTextObj,
              "banner-img": _vm.bannerImg,
              "title-icon": _vm.titleIcon,
              "no-back-btn": _vm.noBackBtn,
              "top-strip": _vm.topStrip,
            },
            on: { closeBanner: _vm.onClose },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [_vm._t("HeaderBody")]
                  },
                  proxy: true,
                },
                {
                  key: "right",
                  fn: function () {
                    return [_vm._t("HeaderRight")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _c("the-dapp-header", {
            attrs: {
              "dapp-name": _vm.bannerText.title,
              "dapp-text": _vm.bannerText.subtext,
              "dapp-img": _vm.dappImg,
            },
          }),
      _vm.tabItems.length > 0 && !_vm.isNewHeader
        ? _c(
            "mew-tabs",
            {
              class: [
                {
                  "pt-5": !_vm.isNewHeader,
                  "hide-default-tab-header": _vm.hideDefaultTabHeader,
                },
              ],
              attrs: {
                items: _vm.tabItems,
                "active-tab": _vm.activeTab,
                background: "transparent",
                "has-underline": "",
                "active-color": "textDark",
              },
              on: { onTab: _vm.onTab },
            },
            [
              _vm._l(_vm.tabItems, function (item, idx) {
                return _c(
                  "template",
                  { slot: "tabContent" + (idx + 1) },
                  [_vm._t("tabContent" + (idx + 1))],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.tabItems.length > 0 && _vm.isNewHeader
        ? _c(
            "v-tabs",
            {
              staticClass: "tab-container",
              attrs: {
                value: _vm.activeTab,
                "background-color": "bgWalletBlockDark",
                color: "blue500",
                height: "46",
                "active-class": "blue500--text",
              },
              on: { change: _vm.onTab },
            },
            _vm._l(_vm.tabItems, function (item, index) {
              return _c(
                "v-tab",
                {
                  key: index,
                  class: [
                    "px-4 px-md-10 textMedium--text menu-tab-text mew-body",
                    { "ml-md-13": index === 0 },
                    { "mr-md-13": index + 1 === _vm.tabItems.length },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.routeToTab(item.route)
                    },
                  },
                },
                [
                  item.hasBadge
                    ? _c(
                        "v-badge",
                        {
                          attrs: {
                            color: "red",
                            content: item.badgeContent,
                            dot: item.badgeContent === "",
                            inline: item.badgeContent !== "",
                            right: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    : _c("div", [_vm._v(" " + _vm._s(item.name) + " ")]),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.activeTab === 0 && _vm.externalContents && _vm.isValidNetwork
        ? _vm._t("tabContent1")
        : _vm._e(),
      _vm.activeTab === 1 && _vm.externalContents && _vm.isValidNetwork
        ? _vm._t("tabContent2")
        : _vm._e(),
      _vm.activeTab === 2 && _vm.externalContents && _vm.isValidNetwork
        ? _vm._t("tabContent3")
        : _vm._e(),
      _vm.tabItems.length > 0 &&
      _vm.isNewHeader &&
      _vm.isValidNetwork &&
      !_vm.externalContents
        ? _c("router-view")
        : _vm._e(),
      _vm.tabItems.length > 0 && _vm.isNewHeader && !_vm.isValidNetwork
        ? _c(
            "div",
            { staticClass: "px-3 py-8 pa-md-15" },
            [
              _c("mew-alert", {
                attrs: {
                  theme: "warning",
                  "hide-close-icon": "",
                  title: "This DApp is not available on this network",
                  description: _vm.networkAlertText,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.tabItems.length === 0
        ? _c(
            "div",
            { staticClass: "pt-8 px-3 pa-md-8" },
            [_vm._t("content")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }