var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module-tokens-value" },
    [
      _c(
        "white-sheet",
        { staticClass: "px-5 px-lg-7 py-5 d-flex justify-space-between" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    class: [_vm.draggable ? "ml-7" : "", "mew-heading-2 mb-3"],
                  },
                  [_vm._v(" " + _vm._s(_vm.tokenTitle) + " ")]
                ),
              ]),
              _vm.showTokens
                ? _c(
                    "v-col",
                    { staticClass: "mt-3", attrs: { cols: "12" } },
                    [
                      _vm._l(_vm.tokens, function (token, idx) {
                        return _c(
                          "v-row",
                          {
                            key: idx,
                            staticStyle: { "max-height": "48px" },
                            attrs: { justify: "start" },
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c("mew-token-container", {
                                  staticClass: "token-shadow",
                                  attrs: { size: "medium", img: token.img },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "mt-2 token-balance textDark--text",
                                attrs: { cols: "6" },
                              },
                              [
                                _vm._v(
                                  _vm._s(token.balancef) +
                                    " " +
                                    _vm._s(token.symbol)
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "token-value mt-2 textDark--text",
                                attrs: { cols: "4", align: "right" },
                              },
                              [_vm._v(" " + _vm._s(token.usdBalancef) + " ")]
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-row",
                        { attrs: { justify: "start" } },
                        [
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _c(
                              "div",
                              { staticClass: "more-tokens textDark--text" },
                              [_vm._v(" " + _vm._s(_vm.getText) + " ")]
                            ),
                          ]),
                          _vm.tokenCount > 0
                            ? _c(
                                "v-col",
                                { attrs: { align: "right", cols: "5" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tokens-link",
                                      on: { click: _vm.checkLink },
                                    },
                                    [_vm._v("See All")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.tokenCount === 0 && _vm.canBuy
                            ? _c(
                                "v-col",
                                { attrs: { align: "right", cols: "5" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tokens-link",
                                      on: { click: _vm.checkLink },
                                    },
                                    [_vm._v("Buy Crypto")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("app-modal", {
        attrs: {
          title: "My Tokens",
          close: _vm.handleTokensPopup,
          show: _vm.showPopup,
          "has-buttons": false,
          scrollable: "",
          width: "700",
        },
        on: { close: _vm.handleTokensPopup },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "mew-heading-3 mb-3 textDark--text px-4" },
                  [
                    _vm._v(
                      " Total Value: " + _vm._s(_vm.totalTokenValues) + " "
                    ),
                  ]
                ),
                _c("module-tokens", {
                  staticClass: "pa-0",
                  attrs: { dense: "" },
                  on: { trade: _vm.handleTokensPopup },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }