var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "ma-n2 ma-md-n3" },
    [
      _c(
        "v-col",
        { staticClass: "pa-2 pa-md-3 d-md-none pb-0", attrs: { cols: "12" } },
        [_c("module-network")],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pa-2 pa-md-3", attrs: { cols: "12", md: "8" } },
        [
          _c(
            "v-row",
            { staticClass: "ma-n2 ma-md-n3" },
            _vm._l(_vm.totalLeftColItems, function (n) {
              return _c(
                "v-col",
                {
                  key: n,
                  staticClass: "pa-2 pa-md-3 pb-0",
                  attrs: { cols: "12" },
                },
                [_vm._t("leftColItem" + n)],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
      !_vm.hasDraggable || _vm.totalRightColItems === 1
        ? _c(
            "v-col",
            { staticClass: "pa-2 pa-md-3", attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-row",
                { staticClass: "ma-n2 ma-md-n3" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-2 pt-4 pa-md-3 d-none d-md-block",
                      attrs: { cols: "12" },
                    },
                    [_c("module-network")],
                    1
                  ),
                  _vm.showUndeadsAdd
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "pa-2 pa-md-3 d-flex align-center justify-center flex-column",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://undeads.com/staking",
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/ad/undeads-ad.png"),
                                  alt: "Undeads Staking",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.totalRightColItems, function (n) {
                    return _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.totalRightColItems >= 1,
                            expression: "totalRightColItems >= 1",
                          },
                        ],
                        key: n,
                        staticClass: "pa-2 pa-md-3 pb-0",
                        attrs: { cols: "12" },
                      },
                      [_vm._t("rightColItem" + n)],
                      2
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.totalRightColItems >= 2 && _vm.hasDraggable
        ? _c(
            "v-col",
            { staticClass: "pa-2 pa-md-3", attrs: { cols: "12", md: "4" } },
            [
              _c(
                "div",
                { staticClass: "d-none d-md-block mb-2" },
                [_c("module-network")],
                1
              ),
              _vm.showUndeadsAdd
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex align-center justify-center flex-column pa-2 pb-4 pa-md-3 pb-md-5",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://undeads.com/staking",
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/ad/undeads-ad.png"),
                              alt: "Undeads Staking",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "draggable",
                _vm._b(
                  {
                    attrs: { handle: ".handle" },
                    on: {
                      start: function ($event) {
                        _vm.drag = true
                      },
                      end: function ($event) {
                        _vm.drag = false
                      },
                    },
                    model: {
                      value: _vm.draggableItems,
                      callback: function ($$v) {
                        _vm.draggableItems = $$v
                      },
                      expression: "draggableItems",
                    },
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                [
                  _c(
                    "transition-group",
                    _vm._l(_vm.draggableItems, function (n) {
                      return _c(
                        "div",
                        { key: n, staticClass: "position--relative mb-2" },
                        [
                          _vm._t("rightColItem" + n),
                          _c(
                            "v-icon",
                            {
                              staticClass: "handle",
                              attrs: { color: "#d6d6d6", size: "23px" },
                            },
                            [_vm._v(" mdi-drag-horizontal-variant ")]
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }