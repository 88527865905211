var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "eth-blocks--block-info",
      style: {
        border: _vm.alertBorderColor,
        "border-radius": "12px",
        overflow: "hidden",
      },
    },
    [
      _c(
        "v-col",
        {
          staticClass: "d-flex align-center",
          style: { "background-color": _vm.alertBgColor, padding: "12px" },
          attrs: { cols: "12" },
        },
        [
          _c(
            "v-icon",
            { class: ["mr-2", _vm.alertTitleColor], attrs: { size: "16px" } },
            [_vm._v(_vm._s(_vm.alertIcon))]
          ),
          _c("p", { class: ["font-weight-bold mb-0", _vm.alertTitleColor] }, [
            _vm._v(" " + _vm._s(_vm.alertTitle) + " "),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "align-center justify-start pa-5",
          attrs: { "no-gutters": "" },
        },
        [
          _vm.isNotAvailable
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex pa-3 align-center mt-3",
                  attrs: { cols: "12" },
                },
                [
                  _c("p", { staticClass: "mb-0 mr-1" }, [_vm._v("Owner:")]),
                  _c(
                    "a",
                    { attrs: { href: _vm.raribleOwnerLink, target: "_blank" } },
                    [_vm._v(" " + _vm._s(_vm.ownerFormatted) + " ")]
                  ),
                ]
              )
            : _vm._e(),
          _vm.isNotAvailable
            ? _c("v-col", { staticClass: "pa-3 mt-1", attrs: { cols: "12" } }, [
                _c(
                  "a",
                  {
                    staticClass: "d-flex align-center",
                    attrs: { href: _vm.raribleLink, target: "_blank" },
                  },
                  [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v("See if it's available on Rarible"),
                    ]),
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-2 greenPrimary--text",
                        attrs: { size: "16px" },
                      },
                      [_vm._v("mdi-open-in-new")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.isAvailable
            ? _c("v-col", { staticClass: "px-2", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "pa-3 d-flex justify-space-between" },
                  [
                    _c("div", { staticClass: "text-end" }, [_vm._v("Price")]),
                    _c("div", { staticClass: "d-flex flex-column text-end" }, [
                      _c("h2", { staticClass: "mb-1 textDark--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formattedPrice) +
                            " " +
                            _vm._s(_vm.network.type.currencyName) +
                            " "
                        ),
                      ]),
                      !_vm.isTestNetwork
                        ? _c("p", { staticClass: "textLight--text mb-0" }, [
                            _vm._v(" " + _vm._s(_vm.formatFiatPrice) + " "),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pa-3 d-flex justify-space-between" },
                  [
                    _c("div", [_vm._v("Transaction fee")]),
                    _c("div", { staticClass: "d-flex flex-column text-end" }, [
                      _c("p", { staticClass: "mb-1 textDark--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.txFee) +
                            " " +
                            _vm._s(_vm.network.type.currencyName) +
                            " "
                        ),
                      ]),
                      !_vm.isTestNetwork
                        ? _c("p", { staticClass: "textLight--text mb-0" }, [
                            _vm._v(" " + _vm._s(_vm.formattedFiatTxFee) + " "),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pa-3 d-flex justify-space-between" },
                  [
                    _c("div", [_vm._v("Total")]),
                    _c("div", { staticClass: "d-flex flex-column text-end" }, [
                      _c("p", { staticClass: "mb-1 textDark--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formattedFiatTotalPrice) +
                            " " +
                            _vm._s(_vm.network.type.currencyName) +
                            " "
                        ),
                      ]),
                      !_vm.isTestNetwork
                        ? _c("p", { staticClass: "textLight--text mb-0" }, [
                            _vm._v(" " + _vm._s(_vm.formattedTotalPrice) + " "),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.isAvailable
            ? _c(
                "v-col",
                {
                  staticClass: "mt-1 d-flex justify-center pa-3",
                  attrs: { cols: "6" },
                },
                [
                  !_vm.isPending
                    ? _c("mew-button", {
                        attrs: {
                          title: _vm.isAdded
                            ? "Added to Batch"
                            : "Add to batch",
                          disabled: _vm.isAdded,
                          "btn-style": "outline",
                          "has-full-width": "",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.addToCart.apply(null, arguments)
                          },
                        },
                      })
                    : _c("mew-button", {
                        attrs: {
                          disabled: "",
                          "btn-style": "outline",
                          title: _vm.isAdded
                            ? "Added to Batch"
                            : "Add to batch",
                          "has-full-width": "",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm.isAvailable
            ? _c(
                "v-col",
                {
                  staticClass: "mt-1 d-flex justify-center pa-3",
                  attrs: { cols: "6" },
                },
                [
                  !_vm.isPending
                    ? _c("mew-button", {
                        attrs: {
                          title: _vm.$vuetify.breakpoint.xs
                            ? "Mint"
                            : "Mint now",
                          disabled: _vm.disableActionBtn,
                          loading: _vm.disableAction,
                          "has-full-width": "",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.emitMint()
                          },
                        },
                      })
                    : _c(
                        "mew-button",
                        { attrs: { disabled: "", "btn-style": "light" } },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row align-center" },
                            [
                              _c("v-progress-circular", {
                                staticClass: "ma-auto",
                                attrs: {
                                  indeterminate: "",
                                  color: "primary",
                                  size: "16",
                                  width: "2",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "textDark--text pl-2" },
                                [_vm._v("Pending")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm.isOwned
            ? _c(
                "v-col",
                {
                  staticClass: "mt-4 pr-sm-2 mb-sm-2 pa-3",
                  attrs: { cols: "12", sm: "6" },
                },
                [
                  !_vm.isPending
                    ? _c("mew-button", {
                        attrs: {
                          "has-full-width": "",
                          title: "Send",
                          "btn-style": "outline",
                          disabled: _vm.disableActionBtn,
                          loading: _vm.disableAction,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.emitOpenSend()
                          },
                        },
                      })
                    : _c(
                        "mew-button",
                        { attrs: { disabled: "", "btn-style": "light" } },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row align-center" },
                            [
                              _c("v-progress-circular", {
                                staticClass: "ma-auto",
                                attrs: {
                                  indeterminate: "",
                                  color: "primary",
                                  size: "16",
                                  width: "2",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "textDark--text pl-2" },
                                [_vm._v("Pending")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm.isOwned
            ? _c(
                "v-col",
                {
                  staticClass: "mt-5 mb-2 mt-sm-4 pl-sm-2 pa-3",
                  attrs: {
                    cols: "12",
                    sm: "6",
                    order: _vm.$vuetify.breakpoint.xs ? "last" : "",
                  },
                },
                [
                  !_vm.isPending
                    ? _c("mew-button", {
                        attrs: {
                          "has-full-width": "",
                          "btn-link": _vm.raribleLink,
                          title: "Sell on OpenSea",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.trackToRarible.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.hasEnoughEth && (_vm.isOwned || _vm.isAvailable)
            ? _c(
                "v-col",
                {
                  class: [
                    "d-flex align-center mt-1",
                    _vm.isOwned ? "justify-start" : "justify-end",
                  ],
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-0 redPrimary--text mew-label d-flex align-center px-3",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.notEnoughMessage) + " "),
                        !_vm.isTestNetwork
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "mew-label font-weight-medium buy-more-link",
                                attrs: {
                                  rel: "noopener noreferrer",
                                  target: "_blank",
                                },
                                on: {
                                  click: function () {
                                    _vm.openBuySell("ETHBlocksInfo")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " Buy more " +
                                    _vm._s(_vm.network.type.name) +
                                    ". "
                                ),
                                _c(
                                  "span",
                                  [
                                    _c("mew-tooltip", {
                                      staticClass: "d-inline-block",
                                      attrs: { text: _vm.estimatedFeesTooltip },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              [
                                _c("mew-tooltip", {
                                  staticClass: "buy-more-link d-inline-block",
                                  attrs: { text: _vm.estimatedFeesTooltip },
                                }),
                              ],
                              1
                            ),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.isReserved
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex align-center mt-3",
                  attrs: { cols: "12" },
                },
                [
                  _c("p", { staticClass: "textLight--text ml-5" }, [
                    _vm._v(
                      " Please contact support@myetherwallet.com to inquire about this block. "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }