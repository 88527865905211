var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "py-12 mt-13 pt-16" }, [
    _c("h1", { staticClass: "mb-5 text-center" }, [
      _vm._v("XDCWallet security policy"),
    ]),
    _c("p", [
      _vm._v(
        " XDCWallet (XOW) looks forward to working with the security community to identify vulnerabilities in order to keep our users and XOW safe. "
      ),
    ]),
    _c("p", [
      _vm._v(
        " XOW is passionate about providing a seamless and secure experience to our community. We are devoted to protecting our users private information and providing them an opportunity to interact with the Ethereum blockchain in the safest manner possible. Security is our first priority and obligation and we always appreciate the valuable contributions! "
      ),
    ]),
    _c("p", [
      _vm._v("Our current bug bounty programs are available on HackenProof."),
    ]),
    _c("p", [
      _vm._v(
        " Program details for the current live version of XDCWallet web and XOW wallet app: "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://hackenproof.com/myetherwallet/myetherwallet",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [_vm._v("https://hackenproof.com/myetherwallet/myetherwallet")]
      ),
    ]),
    _c("p", [
      _vm._v(" Program details for our Enkrypt multichain browser wallet: "),
      _c(
        "a",
        {
          attrs: {
            href: "https://hackenproof.com/myetherwallet/enkrypt-wallet",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [_vm._v("https://hackenproof.com/myetherwallet/enkrypt-wallet")]
      ),
    ]),
    _c("p", [
      _vm._v(
        " Any vulnerabilities submitted under these programs will be used for the purposes of improving MyEtherWallet security as well as the user experience. We aim to establish a positive feedback between XOW and researchers that will contribute to this program - we appreciate your patience as we strive to perfect this process. "
      ),
    ]),
    _c("p", [_vm._v("Thank you.")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }