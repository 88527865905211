var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--side-info-network" },
    [
      _c(
        "white-sheet",
        { staticClass: "py-5", attrs: { sideinfo: !_vm.mobile } },
        [
          _c(
            "div",
            { staticClass: "textDark--text px-5 px-lg-7 mew-heading-2 mb-2" },
            [_vm._v(" " + _vm._s(_vm.$t("common.network")) + " ")]
          ),
          _c(
            "div",
            { staticClass: "px-3" },
            [
              _vm.show
                ? _c(
                    "v-btn",
                    {
                      staticClass: "title-button",
                      attrs: {
                        depressed: "",
                        color: "buttonGrayLight",
                        width: "100%",
                        height: "70px",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.openNetworkOverlay.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between text-transform--none text-decoration--none",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c("div", { staticClass: "text-left" }, [
                            _c("div", { staticClass: "mew-heading-3 mb-2" }, [
                              _vm._v(_vm._s(_vm.fullName)),
                            ]),
                            _c("div", { staticClass: "textMedium--text" }, [
                              _vm._v("Last Block: " + _vm._s(_vm.lastBlock)),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "custom-token-container d-flex align-center justify-center",
                            },
                            [
                              _c("img", {
                                attrs: { width: "40px", src: _vm.icon },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }